.bggradient {
    background: rgb(0, 0, 79);
    background: linear-gradient(137deg, rgba(0, 0, 79, 1) 0%, rgba(50, 6, 69, 1) 25%, rgba(121, 14, 55, 1) 82%, rgba(253, 29, 29, 1) 100%);
}
*::selection{
    background-color:  #016698;
    color : white
}

.bgimg2 {
    background-image: linear-gradient(137.59deg, rgb(253, 27, 192) 17.41%, rgb(248, 237, 0) 98.07%);
}

.iconclr {
    color: white;
    text-align: center;
}

.bodyFont {
    font-family: 'Roboto', sans-serif;
}

.bodyFont2 {
    font-family: 'Kanit', sans-serif;
}


.startDiv {
    position: relative;
    z-index: 30;
}
@media (max-width: 720px) {
    .noneDisp1{
        display: none;
    }
}
@media (min-width: 720px) {
    .noneDisp2{
        display: none;
    }
}
.borderTopic1 {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #016698, #016698) 1;
    /* border-color: green; */
}