.nav-link {
    text-decoration: none;
    display: inline-block;
    position: relative;
}

html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.nav-link1 {
    text-decoration: none;
    display: inline-block;
    position: relative;
}

.nav-link:after {
    background: none repeat scroll 0 0 transparent;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    background: #016698;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    left: 50%;
}

.nav-link:hover:after {
    width: 100%;
    left: 0;
}

.dispNone {
    display: none;
}

.iconclrSocial {
    color: white;
}

.iconclrSocial1 {
    color: #016698;
}

.navClass {
    position: relative;
    z-index: 50;
}

.navClass1 {
    z-index: 30;
}

.leftAlign {
    left: 50%;
}

.bgimg3 {
    background-image: linear-gradient(137.59deg, rgb(253, 27, 192) 17.41%, rgb(248, 237, 0) 98.07%);
}

.btnclr {
    color: white;
}

.iconclr2 {
    color: white;
}
.medicapsLogo{
    width : 170px;
}
@media (min-width: 720px) {
    .dispNone2 {
        display: none;
    }
   
}

@media (max-width: 720px) {
    .bgimg3 {
        background-color: #00033D;
        background-image: none;
        color: white;
    }

    .sideNav {
        background: #016698;
    }

    .iconclrSocial1 {
        color: #00033D;
    }

    .respCss {
        text-align: center;
    }

    .dispNone1 {
        display: none;
    }
     .medicapsLogo{
        width: 100px;
        margin-right: 2rem;
    }
    .iconclrSocial1{
        color: white;
    }

}

@media screen and (min-width:1535px) and (max-width:1560px) {
    .column {
        width: 50%;
    }

    .supHeading {
        font-size: 3.2rem;
    }

    .linkText {
        font-size: 0.98rem;
    }

}



@media screen and (max-width: 1535px) {
    .column {
        width: 50%;
    }

    .supHeading {
        font-size: 2.50rem;

    }

    .linkText {
        font-size: 0.75rem;
    }
    .numHeading{
        font-size: 2.5rem;
    }


}