.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 600px;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 600px;
}

.gmap_iframe {
    height: 600px !important;
    width: 100%;
}